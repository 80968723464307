import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectTeamValuePartners from "../../components/project-team-value-partners"
import { content } from "../pages.module.scss"

export default function ProjektTVP() {
  const currentLang = "pl"
  return (
    <Layout lang={currentLang}>
      <Seo
        lang={currentLang}
        title="Projekt - Team Value Partners"
        keywords={[`powszuk`, `design`]}
      />
      <div className={content}>
        <ProjectTeamValuePartners lang={currentLang} />
      </div>
      <ProjectsNavigation lang={currentLang} project="tvp" />
    </Layout>
  )
}
